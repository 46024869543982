import { SortOrder } from '../../../types'
import {
  SeatInterface,
  TeamInterface,
  TeamInvitesInterface,
} from '../../coaches/store/coaches.types'
import { EAppSubscriptionReasonPackage } from '../ui/form/types'
import { PaymentInterface } from '../../payments/store/payments.types'

export interface UserState {
  userList: UserInterface[]
  isLoading: boolean
  error: UserErrorInterface | null
  selectedUser: UserInterface | null
  totalUsers: number
}

export interface UserErrorInterface {
  graphQLErrors?: []
  networkError?: number
  message: string
}

export interface ScoreInterface {
  score?: number
  prevDayScore?: number
  todayScore?: number
  pillarsIsolatedScore?: [{ pillarSlug: string; score: number }]
  extraPillarsTodayScores?: [{ pillarSlug: string; score: number }]
  pillars?: [{ avgCompletion: number }]
}

export interface AnswerInterface {
  questionSlug?: string
  id: string
  score: string
  value: string
}

export interface UserInterface {
  id: string
  authId: string
  usedDiscord: boolean
  email: string
  fullName: string
  username: string
  birthGender: GenderEnum
  country: string
  enabled: boolean
  birthDate: Date
  timeToBed?: number
  timeOutOfBed?: number
  preferMetricUnits?: boolean
  waterIntakePreference?: number
  healthyMeals?: number
  weight?: number
  hoursAtDesk?: number
  sleepRoutines?: string
  avatar: string
  role: RoleEnum
  teamSeats: number
  createdAt: Date
  updatedAt: Date
  Team?: TeamInterface
  score?: ScoreInterface
  answers?: AnswerInterface[]
  pillarStatistics: [
    { completenessScore: number; date: string; pillarSlug: string }
  ]
  subscription?: string | null
  coachSubscription?: string | null
  appSubscriptionReason?: string | null
  Payments?: PaymentInterface[]
  Seat?: SeatInterface[]
  Invites?: TeamInvitesInterface[]
}

export interface UserWhereUniqueInput {
  id?: string
  authId?: string
  email?: string
}

export enum QueryMode {
  'default' = 'default',
  'insensitive' = 'insensitive',
}

export enum StringFilterModes {
  'default' = 'default',
  'insensitive' = 'insensitive',
}

interface StringFilter {
  contains: string
  mode?: StringFilterModes
}

interface StringNullableFilter {
  in?: string[]
  mode?: QueryMode
  equals?: string
  gt?: string
  lte?: string
}

interface EnumAppSubscriptionSubTypeFilter {
  equals?: EAppSubscriptionReasonPackage
  in?: EAppSubscriptionReasonPackage[]
  notIn?: EAppSubscriptionReasonPackage[]
  not?: EnumAppSubscriptionSubTypeFilter
}

export interface UserWhereInput {
  AND?: Array<UserWhereInput>
  OR?: Array<UserWhereInput>
  email?: StringFilter
  fullName?: StringFilter
  username?: StringFilter
  subscription?: StringNullableFilter
  appSubscriptionReason?: EnumAppSubscriptionSubTypeFilter
  role?: StringNullableFilter
  createdAt?: StringNullableFilter
  enabled?: StringNullableFilter | BoolFilter
}
interface BoolFilter {
  equals?: Boolean | {}
}

export interface UserOrderByInput {
  id?: SortOrder
  email?: SortOrder
  username?: SortOrder
}

export interface UserListMetadataRequest {
  where?: UserWhereInput
}

export interface UserCountAggregate {
  id: number
}

export interface UserListMetadataResponse {
  _count: any
  count?: UserCountAggregate
}

export interface UserListMetadataPayload {
  totalUsers: number
}
export interface UserListRequest {
  where?: UserWhereInput
  orderBy?: UserOrderByInput[]
  cursor?: UserWhereUniqueInput
  take?: number
  skip?: number
}

export interface UserFetchRequest {
  where: UserWhereUniqueInput
}

export enum GenderEnum {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  NONBINARY = 'NONBINARY',
  NOT_SPECIFIED = 'NOT_SPECIFIED',
  INTERSEX = 'INTERSEX',
}

export enum RoleEnum {
  SUPERADMIN = 'SUPERADMIN',
  ADMIN = 'ADMIN',
  DIRECTOR = 'DIRECTOR',
  COACH = 'COACH',
  GAMER = 'GAMER',
  SELF = 'SELF',
}

export interface UserCrudPayload {
  email?: string
  data?: UserInterface
  where?: UserWhereUniqueInput
}
export interface UserRoleUpdatePayload {
  role: string
  email: string
}

interface StringFieldUpdateOperationsInput {
  set: string
}
interface NullableStringFieldUpdateOperationsInput {
  set: string
}

interface BoolFieldUpdateOperationsInput {
  set: boolean
}
interface EnumRoleFieldUpdateOperationsInput {
  set: RoleEnum
}

export interface UserUpdateInput {
  fullName?: StringFieldUpdateOperationsInput
  username?: NullableStringFieldUpdateOperationsInput
  enabled?: BoolFieldUpdateOperationsInput
  role: EnumRoleFieldUpdateOperationsInput
}

export interface UserUpdatePayload {
  where: UserWhereUniqueInput
  data: UserUpdateInput
}

export enum USER {
  USER_ACTION_LOADING = 'user:loading',
  USER_FETCH_REQUEST = 'user:fetch:request',
  USER_FETCH_SUCCESS = 'user:fetch:success',
  USER_FETCH_FAILED = 'user:fetch:failed',
  USER_LIST_REQUEST = 'user:list:request',
  USER_LIST_SUCCESS = 'user:list:success',
  USER_LIST_FAILED = 'user:list:failed',
  USER_UPDATE_REQUEST = 'user:update:request',
  USER_UPDATE_SUCCESS = 'user:update:success',
  USER_UPDATE_FAILED = 'user:update:failed',
  USER_DELETE_REQUEST = 'user:delete:request',
  USER_DELETE_SUCCESS = 'user:delete:success',
  USER_DELETE_FAILED = 'user:delete:failed',
  USER_DISABLE_REQUEST = 'user:disable:request',
  USER_DISABLE_SUCCESS = 'user:disable:success',
  USER_DISABLE_FAILED = 'user:disable:failed',
  USER_ENABLE_REQUEST = 'user:enable:request',
  USER_ENABLE_SUCCESS = 'user:enable:success',
  USER_ENABLE_FAILED = 'user:enable:failed',
  USER_LIST_METADATA_REQUEST = 'user:metadata:request',
  USER_LIST_METADATA_SUCCESS = 'user:metadata:success',
  USER_LIST_METADATA_FAILED = 'user:metadata:failed',
}
