import { extendTheme } from '@chakra-ui/react'

const config: any = {
  initialColorMode: 'dark',
  useSystemColorMode: false,
}

export const colors = {
  colors: {
    brand: {
      50: '#a541f4',
      100: '#953bdc',
      200: '#8434c3',
      300: '#732eab',
      400: '#632792',
      500: '#53217a',
      600: '#421a62',
      700: '#321449',
      800: '#210d31',
      900: '#100618',
    },
    fitgmrBlue: {
      50: '#3eb1f2',
      100: '#389fda',
      200: '#328ec2',
      300: '#2b7ca9',
      400: '#256a91',
      500: '#1f5979',
      600: '#194761',
      700: '#133549',
      800: '#0c2330',
      900: '#061218',
    },
    fitgmrRed: {
      50: '#f84b75',
      100: '#df4469',
      200: '#c63c5e',
      300: '#ae3552',
      400: '#952d46',
      500: '#7c263b',
      600: '#631e2f',
      700: '#4a1723',
      800: '#320f17',
      900: '#19070c',
    },
    fitgmrGreen: {
      50: '#4df9a6',
      100: '#45e095',
      200: '#3ec785',
      300: '#36ae74',
      400: '#2e9564',
      500: '#277d53',
      600: '#1f6442',
      700: '#174b32',
      800: '#0f3221',
      900: '#081911',
    },
    fitgmrOrange: {
      50: '#ff9901',
      100: '#e68a01',
      200: '#cc7a01',
      300: '#b36b01',
      400: '#995c01',
      500: '#804d01',
      600: '#663d00',
      700: '#4d2e00',
      800: '#331f00',
      900: '#190f00',
    },
    fitgmrPurple: {
      50: '#c985ff',
      100: '#b578e6',
      200: '#a16acc',
      300: '#8d5db3',
      400: '#795099',
      500: '#654380',
      600: '#503566',
      700: '#3c284d',
      800: '#281b33',
      900: '#140d19',
    },
    darkgray: {
      200: '#333333',
      700: '#272727',
    },
    colorStep: {
      0: '#0d0d0d',
      100: '#1a1a1a',
    },
  },
}

const globalStyles = {
  components: {
    Button: {
      baseStyle: {
        borderRadius: 'full',
      },
    },
  },
  styles: {
    global: {
      'html, body': {
        background: '#2e3134',
      },
    },
  },
}

const theme = extendTheme({ config, ...globalStyles, ...colors })

export default theme
