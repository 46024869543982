import {
  UserInterface,
  UserOrderByInput,
  UserWhereInput,
  UserWhereUniqueInput,
} from '../../users/store/users.types'

export interface TeamState {
  team: TeamInterface
  isLoading: boolean
  error: TeamErrorInterface | null
  totalUsers: number
}

export interface TeamErrorInterface {
  graphQLErrors?: []
  networkError?: number
  message: string
}

export enum TeamInviteStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export interface TeamInvitesInterface {
  id: string
  teamId: string
  inviterUserId: string
  inviteeEmail: string
  inviteeName: string
  role: string
  status: TeamInviteStatus
  TeamInviter: UserInterface
  Invite?: UserInterface
  Team: TeamInterface
  createdAt: string
  updatedAt: string
}

export interface TeamInterface {
  id: string
  name: string
  description?: string
  avatar?: string
  game?: string
  createdAt?: string
  updatedAt?: string
  users?: UserInterface[]
  TeamInvites?: TeamInvitesInterface[]
  ownerId?: string
  Seats: SeatInterface[]
  TeamOwner?: UserInterface
  coaches?: UserInterface[]
  notificationCount?: number
}

interface UserSeatInterface {
  id: string
  role: string
}
export interface SeatInterface {
  id: string
  userId: string
  teamId: string
  active: boolean
  User?: UserSeatInterface
}

export interface TeamSeatInterface {
  id: string
  userId?: string
  teamId?: string
  active?: boolean
  isDefaultSeat: boolean
  endSubscriptionAt?: string
  seatRole?: string
  User?: UserInterface
}

export interface TeamCodeInterface {
  id: string
  code: string
  teamId: string
  used: boolean
  accepted: boolean
  email?: string
  userId?: string
  inviteId?: string
}

export interface TeamWhereUniqueInput {
  id?: string
  name?: string
}

export interface TeamUsersListRequest {
  where?: UserWhereInput
  orderBy?: UserOrderByInput[]
  cursor?: UserWhereUniqueInput
  take?: number
  skip?: number
}

export interface TeamFetchRequest {
  where: TeamWhereUniqueInput
  usersWhere?: TeamUsersListRequest['where']
  usersOrderBy?: TeamUsersListRequest['orderBy']
  usersCursor?: TeamUsersListRequest['cursor']
  usersTake?: TeamUsersListRequest['take']
  usersSkip?: TeamUsersListRequest['skip']
}

export interface TeamUpdateInput {
  name: StringFieldUpdateOperationsInput
  description: NullableStringFieldUpdateOperationsInput
  avatar: NullableStringFieldUpdateOperationsInput
  game: NullableStringFieldUpdateOperationsInput
}

interface StringFieldUpdateOperationsInput {
  set: string
}
interface NullableStringFieldUpdateOperationsInput {
  set: string
}

export interface TeamUpdatePayload {
  where: TeamWhereUniqueInput
  data: TeamUpdateInput
  usersWhere?: TeamUsersListRequest['where']
  usersOrderBy?: TeamUsersListRequest['orderBy']
  usersCursor?: TeamUsersListRequest['cursor']
  usersTake?: TeamUsersListRequest['take']
  usersSkip?: TeamUsersListRequest['skip']
}

export interface TeamCreateWithoutUsersInput {
  id?: string
  name: string
  description?: string
  avatar?: string
  game?: string
  organization?: string
}

export interface TeamCreatePayload {
  data: TeamCreateWithoutUsersInput
}

export interface TeamInvitePayload {
  role: string
  inviteeName: string
  inviteeEmail: string
}

export interface TeamRemoveUserPayload {
  userId: string
  teamId: string
}

export interface TeamScoreData {
  id: string

  date: string

  pillarSlug?: string

  fitgmrScore?: number

  gameReadiness?: number

  score?: number

  pureScore?: number

  completenessScore?: number

  totalAnswers?: number

  totalQuestions?: number

  completenessScoreForRequiredOnly?: number

  totalRequiredAnswers?: number

  totalRequiredQuestions?: number

  GamePerformance?: number

  question1?: string

  answer1?: string

  question2?: string

  answer2?: string

  question3?: string

  answer3?: string

  question4?: string

  answer4?: string

  question5?: string

  answer5?: string

  question6?: string
  answer6?: string
  question7?: string
  answer7?: string
  question8?: string
  answer8?: string
  question9?: string
  answer9?: string
}

export interface ProUserData {
  userScoreStatistics?: TeamScoreData[]
  userSpecificData?: UserInterface[]
}

export interface GetMessagesData {
  teamId: String
  date: String
  userId: String
}

export interface GetUserAnswer {
  questionSlug: String
  date: String
  userId: String
}

export enum TEAM {
  TEAM_ACTION_LOADING = 'team:loading',
  TEAM_FETCH_REQUEST = 'team:fetch:request',
  TEAM_FETCH_SUCCESS = 'team:fetch:success',
  TEAM_FETCH_FAILED = 'team:fetch:failed',
  TEAM_CREATE_REQUEST = 'team:create:request',
  TEAM_CREATE_SUCCESS = 'team:create:success',
  TEAM_CREATE_FAILED = 'team:create:failed',
  TEAM_UPDATE_REQUEST = 'team:update:request',
  TEAM_UPDATE_SUCCESS = 'team:update:success',
  TEAM_UPDATE_FAILED = 'team:update:failed',
  TEAM_INVITE_REQUEST = 'team:invite:request',
  TEAM_INVITE_FAILED = 'team:invite:failed',
  TEAM_DELETE_INVITE_REQUEST = 'team:cancel_invite:request',
  TEAM_REMOVE_USER_INVITE_SUCCESS = 'team:remove_user_invite:success',
  TEAM_DELETE_INVITE_FAILED = 'team:cancel_invite:failed',
  TEAM_REMOVE_USER_REQUEST = 'team:remove:user:request',
  TEAM_REMOVE_USER_SUCCESS = 'team:remove:user:success',
  TEAM_REMOVE_USER_FAILED = 'team:remove:user:failed',
  TEAM_SET_SEATS = 'team:set:seats',
}
