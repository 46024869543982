import { gql } from '@apollo/client'

export const getSubscriptionsMethod = 'getSubscriptions'
const getSubscriptions = gql`
  query SubscriptionConfigs {
    subscriptionConfigs {
      name
      id
    }
  }
`

export const userKey = 'me'
const getUser = gql`
  query Me {
    ${userKey} {
      id
      authId
      username
      fullName
      birthGender
      actualGender
      fitbitUserId
      fitbitAccessToken
      fitbitRefreshToken
      country
      email
      teamSeats
      usedDiscord
      createdAt
      updatedAt
      avatar
      subscription
      birthDate
      weight
      height
      timeToBed
      timeOutOfBed
      hoursAtDesk
      gameRoutines
      sleepRoutines
      waistCircumference
      hipCircumference
      healthyMeals
      preferMetricUnits
      waterIntakePreference
      onboardingStatus
      shownGettingStartedCompleted
      subscription
      appSubscriptionReason
      coachSubscription
      role
      shareScore
      Invites {
        id
        teamId
        role
        status
        TeamInviter {
        id
        fullName
        avatar
        }
        Team {
          id
          name
          description
          avatar
          game
        }
      }
    }
  }
`
export const getCardDetailsMethod = 'cardDetails'
const getCardDetails = gql`
  query CardDetails {
    ${getCardDetailsMethod} {
      lastFour
    }
  }
`

export const AUTH_QUERIES = {
  GET_SUBSCRIPTIONS_STRIPE: getSubscriptions,
  GET_USER: getUser,
  GET_CARD_DETAILS: getCardDetails,
}
