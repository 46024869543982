import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useLazyQuery } from '@apollo/client'
import {
  setRedirectUrl,
  setUpdateUser,
} from '../components/auth/store/auth.actions'
import { IUser } from '../components/auth/store/auth.types'
import {
  AUTH_QUERIES,
  userKey,
} from '../components/auth/mutations/auth.queries'
import {
  TeamInvitesInterface,
  TeamInviteStatus,
} from '../components/coaches/store/coaches.types'
import { RoleEnum } from '../components/users/store/users.types'

export const useGetUserOnLogin = ({ fromSignUp = false }) => {
  const route = useHistory()
  const dispatch = useDispatch()

  const [getUser] = useLazyQuery(AUTH_QUERIES.GET_USER, {
    fetchPolicy: 'cache-and-network',
    onCompleted(userData: { [userKey]: IUser }) {
      if (userData?.[userKey]) {
        dispatch(setUpdateUser(userData[userKey]))
        const coachPendingInvites = userData[userKey].Invites.some(
          (invite: TeamInvitesInterface) =>
            invite.role === RoleEnum.COACH &&
            invite.status === TeamInviteStatus.PENDING
        )

        const redirectFromSignUp = coachPendingInvites
          ? '/home/coach-portal?tab=team-invite'
          : '/pricing'
        const redirectFromSignIn = coachPendingInvites
          ? '/home/coach-portal?tab=team-invite'
          : '/home'
        if (fromSignUp) route.push(redirectFromSignUp)
        else route.push(redirectFromSignIn)
        dispatch(setRedirectUrl({ redirectTo: '' }))
      }
    },
    onError() {},
  })

  return {
    getUser,
  }
}
